import { gql } from '@apollo/client'

export const ManufacturerProductBimLinkDetailFragment = gql`
    fragment manufacturerProductBimLinkDetailFragment on ManufacturerProductBimLinkDetail {
        bimUrl
        imageUrl
        productDescription
        productName
    }
`
export const ManufacturerProductPropertiesOptionFragment = gql`
    fragment manufacturerProductPropertiesOptionFragment on ManufacturerProductPropertyOptionDetail {
        isOther
        isPremium
        isPremiumPlus
        isSupported
        optionId
        value
        isUnitOfMeasure
        metricUnits
        imperialUnits
        isInlineTextOnly
    }
`
export const ManufacturerProductPropertiesOptionGroupFragment = gql`
    fragment manufacturerProductPropertiesOptionGroupFragment on ManufacturerProductPropertyOptionGroupDetail {
        groupId
        prefix
        postfix
        options {
            ...manufacturerProductPropertiesOptionFragment
        }
    }
    ${ManufacturerProductPropertiesOptionFragment}
`
export const ManufacturerProductCatalogDetailFragment = gql`
    fragment manufacturerProductCatalogDetailFragment on ManufacturerProductCatalogDetail {
        path
        thumbnailPath
        title
        uploadedDate
    }
`

export const ManufacturerProductSpecDocDetailFragment = gql`
    fragment manufacturerProductSpecDocDetailFragment on ManufacturerProductSpecDocDetail {
        docType
        docVariation
        expirationDate
        path
        title
        id
    }
`

export const ManufacturerProductDetailFragments = {
    imagesFragment: gql`
        fragment imagesFragment on ManufacturerProductImageDetail {
            id
            filename
            isPrimary
            order
            originalFilename
        }
    `,
    videosFragment: gql`
        fragment videosFragment on ManufacturerProductVideoDetail {
            videoStillImageUrl
            fromSource
            videoId
            id
            order
        }
    `,
    webLinksFragment: gql`
        fragment webLinksFragment on ManufacturerProductWebLinkDetail {
            order
            type
            url
        }
    `,
    listingsFragment: gql`
        fragment listingsFragment on ManufacturerProductListingDetail {
            catalogs {
                ...manufacturerProductCatalogDetailFragment
            }
            manufacturerDocs {
                ...manufacturerProductSpecDocDetailFragment
            }
            id
            sectionNumber
            bimLinks {
                ...manufacturerProductBimLinkDetailFragment
            }
        }
        ${ManufacturerProductCatalogDetailFragment}
        ${ManufacturerProductSpecDocDetailFragment}
        ${ManufacturerProductBimLinkDetailFragment}
    `,
    assignedBPMUsersFragment: gql`
        fragment assignedBPMUsersFragment on User {
            id
            status
            email
            role
            profileInfo {
                firstName
                lastName
                professionalRole
                professionalDiscipline
            }
        }
    `,
    assignedDeltekUsersFragment: gql`
        fragment assignedDeltekUsersFragment on User {
            id
            status
            email
            role
            profileInfo {
                firstName
                lastName
                professionalRole
                professionalDiscipline
            }
        }
    `,
    saveBPMUsersFragment: gql`
        fragment saveBPMUsersFragment on AssignedUser {
            userId
            role
        }
    `,
    saveDeltekUsersFragment: gql`
        fragment saveDeltekUsersFragment on AssignedUser {
            userId
            role
        }
    `,
    propertiesFragment: gql`
        fragment propertiesFragment on ManufacturerProductPropertyDetail {
            options {
                ...manufacturerProductPropertiesOptionFragment
            }
            optionGroups {
                ...manufacturerProductPropertiesOptionGroupFragment
            }
            propertyId
            propertyName
            isPremiumPlus
        }
        ${ManufacturerProductPropertiesOptionFragment}
        ${ManufacturerProductPropertiesOptionGroupFragment}
    `,
    partnershipLinksFragment: gql`
        fragment partnershipLinksFragment on Partnership {
            level
            name
            partnerType
            url
        }
    `,
}
