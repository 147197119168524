import { gql } from '@apollo/client'
import {
    ManufacturerProductCatalogDetailFragment,
    ManufacturerProductDetailFragments,
    ManufacturerProductSpecDocDetailFragment,
} from '../fragments/productDetail.fragments'

const listingMatchFragment = gql`
    fragment listingMatchFragment on ListingMatch {
        listingId
        categories
        displayNameWithFirmName
        firmName
        firmDisplayName
        productName
        productId
        productType
        specAgentProductTypeId
        firmLogoUrl
        sectionName
        type
        submissionStatus
        lastUpdated
        isActive
        firmId
        images {
            ...imagesFragment
        }
        videos {
            ...videosFragment
        }
        catalogs {
            ...manufacturerProductCatalogDetailFragment
        }
        manufacturerDocs {
            ...manufacturerProductSpecDocDetailFragment
        }
        canAutoGenerateProductSpecification
        familyNumber
        partnershipLinksBIM
        partnershipLinksSwatchbox
        partnershipLinksSM
        partnershipLinksULE
        specAgentProductTypeId
        hasManufacturerOptions
    }
    ${ManufacturerProductDetailFragments.imagesFragment}
    ${ManufacturerProductDetailFragments.videosFragment}
    ${ManufacturerProductCatalogDetailFragment}
    ${ManufacturerProductSpecDocDetailFragment}
`

export const ProductSearchQuery = gql`
    query productSearchQuery(
        $manufacturerProductSearchInput: ManufacturerProductSearchInput!
    ) {
        listingSearch(input: $manufacturerProductSearchInput) {
            facets {
                name
                values {
                    value
                }
            }
            matches {
                ...listingMatchFragment
            }
            count
        }
    }
    ${listingMatchFragment}
`

export const ProductSearchAutosuggestQuery = gql`
    query listingAutoSuggest($input: ManufacturerProductAutoSuggestInput!) {
        listingAutoSuggest(input: $input) {
            result
            searchAfterKey
        }
    }
`
export const GetAllProducts = gql`
    query getAllProducts(
        $manufacturerProductSearchInput: ManufacturerProductSearchInput!
    ) {
        listingSearch(input: $manufacturerProductSearchInput) {
            matches {
                productName
                listingId
                firmId
            }
        }
    }
`

export const GetCompanyListingsQuery = gql`
    query productListingsQuery(
        $listingSearchInput: ManufacturerProductSearchInput!
    ) {
        listingSearch(input: $listingSearchInput) {
            facets {
                name
                values {
                    count
                    value
                }
            }
        }
    }
`

export const ProductListingDetailsQuery = gql`
    query getListingDetail($listingIdInput: GetListingDetailInput!) {
        getListingDetail(input: $listingIdInput) {
            model
            productId
            listingId
            productName
            firmLogoUrl
            firmId
            firmName
            firmPartnershipTypes
            categories
            specAgentProductTypeId
            productType
            submissionStatus
            isActive
            isPremiumPlus
            buildingTypes
            firstPublished
            lastPublished
            shortDescription
            longDescription
            sectionNumber
            sectionTitle
            sectionName
            type
            lastUpdated
            properties {
                ...propertiesFragment
            }
            images {
                ...imagesFragment
            }
            videos {
                ...videosFragment
            }
            webLinks {
                ...webLinksFragment
            }
            catalogs {
                ...manufacturerProductCatalogDetailFragment
            }
            manufacturerDocs {
                ...manufacturerProductSpecDocDetailFragment
            }
            partnershipLinks {
                ...partnershipLinksFragment
            }
            canAutoGenerateProductSpecification
        }
    }
    ${ManufacturerProductDetailFragments.webLinksFragment}
    ${ManufacturerProductDetailFragments.imagesFragment}
    ${ManufacturerProductDetailFragments.videosFragment}
    ${ManufacturerProductDetailFragments.propertiesFragment}
    ${ManufacturerProductCatalogDetailFragment}
    ${ManufacturerProductSpecDocDetailFragment}
    ${ManufacturerProductDetailFragments.partnershipLinksFragment}
`
